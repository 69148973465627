import CodeExample from "components/docs/code-examples/CodeExample";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import LINKS from "components/docs/navigation/links";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import UnevenTwoColumnCenteredSection from "components/docs/sections/UnevenTwoColumnCenteredSection";
import CodeExampleExplainer from "components/docs/shared-components/CodeExampleExplainer";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { WarningCard } from "components/docs/shared-components/NoticeCards";
import SectionDivider from "components/docs/shared-components/SectionDivider";
import { StaticFancyPageContainerWithTOC } from "components/docs/shared-components/StaticFancyPageContainer";
import { Link, PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";

/**
 * Small width section with padding
 */
const Section = styled(SmallWidthSection).attrs({ className: "mt-9 mb-9" })``;

/**
 * Small width section with smaller padding above
 */
const ShortSection = styled(SmallWidthSection).attrs({ className: "mb-9" })`
  margin-top: 0px;
`;

const Pretitle = styled(CodeExampleExplainer)`
  &&&& {
    margin-bottom: 12px;
  }
`;

// Consolidates some repeated code in this file
const RelatedAndNestedCodeExample = ({
  endpoint,
  extraTitleContent,
  fileName,
}: {
  endpoint: string;
  extraTitleContent?: React.ReactElement;
  fileName: string;
}) => {
  const title = (
    <p>
      Example body of POST request to <code>{endpoint}</code>
      {extraTitleContent}
    </p>
  );
  return (
    <CodeExample
      folder="merge-writes/writes/related-and-nested"
      codeBlockName={title}
      fileBaseName={fileName}
      style={{ maxHeight: "none" }}
      hasLineNumbers={false}
      isVerticallyPadded={false}
    />
  );
};

/**
 * Creates a guide for Related and Nested Writes
 */
const RelatedAndNestedWrites = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => (
  <StaticFancyPageContainerWithTOC
    tableOfContents={[
      {
        text: "Overview",
      },
      {
        text: "Writing Data with Relations",
        anchorSlug: "writing-data-with-relations-to-existing-data",
      },
      {
        text: "Writing Nested Data",
      },
    ]}
  >
    <DocsHelmet
      title="Merge Writes: Related and Nested"
      description="Learn how to use Related and Nested Writes in your product."
    />
    <SmallWidthSection>
      <NavigationScrollTracker>
        <HeaderBar
          title="Related and Nested Writes"
          subtitle="Write data to third-party platforms with relations to existing and new Common Model instances"
        />
      </NavigationScrollTracker>
    </SmallWidthSection>

    <ShortSection>
      <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Overview">
        <p>Common Models sometimes have relationships with other Common Models.</p>
        <p>For example:</p>
        <ul>
          <li>
            Merge HRIS <code>Employees</code> can have related <code>Employments</code>
          </li>
          <li>
            Merge ATS <code>Applications</code> can have related <code>Candidates</code>
          </li>
          <li>
            Merge ATS <code>Candidates</code> can have related <code>Attachments</code>
          </li>
        </ul>
        <p>Our POST endpoints allow you to:</p>
        <ul>
          <li>Create relationships to existing entities through references to Merge ID’s</li>
          <li>Create new related entities through nesting</li>
        </ul>
        <WarningCard>
          <p>
            You cannot create references to the Merge IDs of existing Common Model instances at the
            same time as a Nested Write.
          </p>
        </WarningCard>
      </NavigationScrollTrackerWithAnchor>
    </ShortSection>
    <SectionDivider />

    <Section>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h3"
        title="Writing Data with Relations to Existing Data"
      >
        <p>
          When sending POST requests to Merge, related Common Model instances can be referenced by
          their Merge ID.
        </p>
        <aside>
          <p>
            <strong>Supported Relations</strong>
          </p>
          <p>
            All Common Model fields that accept references to other Common Model instances by their
            Merge ID are supported in POST requests.
          </p>
          <p>
            Refer to our <Link to={LINKS.API_REFERENCE.linkTo}>API reference</Link> to see what
            references are supported.
          </p>
        </aside>
      </NavigationScrollTrackerWithAnchor>
    </Section>

    <UnevenTwoColumnCenteredSection
      className="pt-9"
      columnA={
        <>
          <Pretitle>HRIS Example</Pretitle>
          <p>
            When creating an <code>Employee</code> using our HRIS API, a relationship between an{" "}
            <code>Employee</code> and an existing <code>Team</code> can be created by adding the
            Merge ID of the relevant <code>Team</code> to the new <code>Employee’s team</code>{" "}
            field.
          </p>
        </>
      }
      columnB={<RelatedAndNestedCodeExample endpoint="/employees" fileName="hris-relation" />}
    />
    <UnevenTwoColumnCenteredSection
      columnA={
        <>
          <Pretitle>ATS Example</Pretitle>
          <p>
            When creating an <code>Attachment</code> using our ATS API, a relationship between an{" "}
            <code>Attachment</code> and an existing <code>Candidate</code> is created by adding the
            Merge ID of the relevant <code>Candidate</code> to the new{" "}
            <code>Attachment’s candidate</code> field.
          </p>
        </>
      }
      columnB={<RelatedAndNestedCodeExample endpoint="/attachments" fileName="ats-relation" />}
    />
    <SectionDivider />

    <Section>
      <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Writing Nested Data">
        <p>
          Our POST endpoints allow you to create related Common Models in-line (also known as a{" "}
          <strong>Nested Write</strong>) if the related entity hasn’t been created yet.
        </p>
        <aside className="mt-9">
          <p>Nested Writes are supported for only the following use cases at this time:</p>
          <ul className="mb-0">
            <li>
              <code>Employee</code> — single nested <code>Employment</code> in{" "}
              <code>Employee’s employments</code> field
            </li>
            <li>
              <code>Candidate</code> — single nested <code>Application</code> in{" "}
              <code>Candidate’s applications</code> field
            </li>
            <li>
              <code>Candidate</code> — multiple nested <code>Attachments</code> in{" "}
              <code>Candidate’s attachments</code> field
            </li>
          </ul>
        </aside>
      </NavigationScrollTrackerWithAnchor>
    </Section>
    <UnevenTwoColumnCenteredSection
      className="pt-9"
      columnA={
        <>
          <Pretitle>HRIS Example</Pretitle>
          <p>
            When creating an <code>Employee</code> using our HRIS API, to create a new related{" "}
            <code>Employment</code> within a new <code>Employee</code>, you would pass the details
            of the <code>Employment</code> in an object within the new{" "}
            <code>Employee’s employments</code> field.
          </p>
        </>
      }
      columnB={
        <RelatedAndNestedCodeExample
          endpoint="/employees"
          extraTitleContent={
            <>
              {" "}
              with nested <code>employment</code>
            </>
          }
          fileName="hris-nested"
        />
      }
    />
    <UnevenTwoColumnCenteredSection
      columnA={
        <>
          <Pretitle>ATS Example</Pretitle>
          <p>
            When creating a <code>Candidate</code> using our ATS API, to create a new related{" "}
            <code>Application</code> within a new <code>Candidate</code>, you would pass the details
            of the <code>Application</code> in an object within the new{" "}
            <code>Candidate’s applications</code> field.
          </p>
        </>
      }
      columnB={
        <RelatedAndNestedCodeExample
          endpoint="/candidates"
          extraTitleContent={
            <>
              {" "}
              with nested <code>applications</code>
            </>
          }
          fileName="ats-nested"
        />
      }
    />

    <Section>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </Section>
  </StaticFancyPageContainerWithTOC>
);

export default RelatedAndNestedWrites;
